<template>
  <div>
    <div>
      <el-upload
        ref="upload"
        name="file"
        :action="baseUrl + 'util/file/upload'"
        :before-upload="beforeUpload"
        :on-success="handleSuccess"
        :show-file-list="false"
        :on-error="handleError"
        :with-credentials="true"
        :data="{ folder: folder }"
        :multiple="multiple"
      >
        <div v-if="type === 'images'" class="image-upload-trigger">
          <i v-if="loading" class="el-icon-loading"></i>
          <i v-else class="el-icon-plus"></i>
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
// import request from '@/utils/service'

export default {
  props: ['type', 'folder', 'multiple', 'registerStatus'],

  data() {
    return {
      baseUrl: '/pet_supplies_mall/',
      // baseUrl: '/test_pet_supplies_mall/',
      loading: false
    }
  },

  methods: {
    beforeUpload(file) {
      if (this.registerStatus) {
        this.loading = true
        return true
      }

      const fileType = ['image/jpeg', 'image/png'].find((v) => {
        return file.type === v
      })
      const accessType = !(fileType === undefined)
      let exceedMaxSize = true
      if (!accessType) {
        this.$message.error('格式不符')
      }
      // else {
      //   exceedMaxSize = file.size / 1024 / 1024 < 10
      //   if (!exceedMaxSize) {
      //     this.$message.error('文件大小不符合');
      //   }
      // }
      this.loading = accessType && exceedMaxSize
      return accessType && exceedMaxSize
    },

    handleSuccess(res, file) {
      this.$message.success('Exitoso')
      this.loading = false

      this.$emit('listenPostImage', res.result)
    },

    handleError() {
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.absolute-btn {
  position: absolute;
  top: 3px;
  right: 10px;
}

.image-upload-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  margin-right: 15px;
  color: #777;
  background-color: #f7f8fa;
  border-radius: 4px;
  i {
    font-size: 20px;
    font-weight: 700;
  }
}
</style>
