/*
 * @Author: huanglin.like@qq.com
 * @LastEditTime: 2024-03-07 14:37:27
 */
'use strict'

import { Message } from 'element-ui'

import Vue from 'vue'
import axios from 'axios'

let config = {
  baseURL: process.env.VUE_APP_REQUEST_URL,
  timeout: 90000
}

const _axios = axios.create(config)
console.log('axios', config)
_axios.interceptors.request.use(
  function (config) {
    // 获取缓存的 token
    const token = localStorage.getItem('token')

    // 给axios请求带上token，不然后面需要token的接口请求不了
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  (res) => res,
  (error) => {
    console.log('error.response :>> ', error.response)
    if (error.response.status === 400) {
      Message.error(error.response.data.message)
    } else if (error.response.status === 401) {
      localStorage.removeItem('token')
      location.href = '/login'
    } else {
    }

    return Promise.reject(error)
  }
)

Plugin.install = function (Vue, options) {
  options
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
