<template>
  <div v-if="imageWall.visible" class="image-wall">
    <div class="image-wall-wrapper">
      <img class="image" :src="imageWall.url | filterPicture" />
      <div @click="$store.commit('HIDDEN_IMAGE_WALL')" class="wall-close">
        <i class="el-icon-close" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      imageWall: (state) => state.app.imageWall
    })
  }
}
</script>

<style lang="scss" scoped>
.image-wall {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  z-index: 10000;
  .image-wall-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .image {
      max-width: 90vw;
      max-height: 90vh;
    }
    .wall-close {
      position: fixed;
      text-align: center;
      border-radius: 40px;
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      z-index: 2;
      cursor: pointer;
      .el-icon-close {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        background-color: #fff;
      }
    }
  }
}
</style>
