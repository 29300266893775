<!--
吸顶组件
用法: <StickTop>
        <div>李白</div>
    </StickTop>


    <StickTop :offsetTop="30">
        <div>李白</div>
    </StickTop>
    属性
        offsetTop Number类型    距离顶部的距离(px)  默认为0
-->

<template>
  <div>
    <div ref="point" style="z-index: 2000" :style="fixedStyle">
      <slot></slot>
    </div>
    <div v-show="affix" :style="placeholderStyle"></div>
  </div>
</template>
<script>
/*
    在滚动过程中计算获取元素在没有fixed定位的时候的距离顶部的距离 (iview是这么做的)
 */
function getOffset(element) {
  const rect = element.getBoundingClientRect() // 这两行代码不兼容IE (兼容IE的方法是 document.documentElement.scrollTop / scrollLeft)
  const scrollTop = window.pageYOffset
  const scrollLeft = window.pageXOffset /*
    相加后的结果是 初始位置 在滚动过程中他将是一个固定值
    */
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft
  }
}

export default {
  props: {
    offsetTop: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      affix: false, // 吸顶状态
      fixedStyle: {
        width: ''
      }, // 吸顶元素的样式
      placeholderStyle: {} // 占位容器
    }
  },
  methods: {
    handleScroll() {
      // getOffset方法是从iview抄来的
      // https://gitee.com/view-design/ViewUI/blob/master/src/components/affix/affix.vue
      const rect = getOffset(this.$el) // 根元素距离顶部和左侧的距离 主要是想获取初始值
      console.log('rect', rect)
      if (rect.top - this.offsetTop < window.pageYOffset) {
        this.fixedStyle = {
          position: 'fixed',
          top: '120px',
          // margin: 'auto',
          right: '0px',
          left: '0px',
          width: '285px'
        }
        console.log('this.fixedStyle', this.fixedStyle)
        this.placeholderStyle = { height: `${this.$refs.point.clientHeight}px` }
        this.affix = true
      } else {
        this.fixedStyle = null
        this.placeholderStyle = {}
        this.affix = false
      }
    }
  },
  mounted() {
    addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    removeEventListener('scroll', this.handleScroll)
  }
}
</script>
