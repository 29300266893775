import Vue from 'vue'
import App from './App.vue'
import './plugins/axios'
import store from './store'
import Element from 'element-ui'
import '@/styles/index.scss'
import './components/globalRC.js'
import { deepCopy } from './myCode/myCode'
Vue.prototype.$deepCopy = deepCopy
// Vue.prototype.$resetForm = resetForm

Vue.use(Element)

import Vuex from 'vuex'

Vue.use(Vuex)

// 路由导航缓存
import VuePageStack from 'vue-page-stack'

Vue.use(VuePageStack, { router })

import 'element-ui/lib/theme-chalk/index.css'
import 'common-css-myself'

import router from './router'

let firstLoad = true

router.beforeEach((to, from, next) => {
  if (to.meta.title) document.title = to.meta.title

  let token = localStorage.getItem('token')

  if (token) {
    // 获取用户信息，只执行一次
    if (firstLoad) {
      firstLoad = false
      Vue.axios.post('/member/info', {}).then((res) => {
        let data = res.data.result
        if (data.registrationStatus !== 2) {
          return next('/login')
        }

        store.commit('SET_INFO', res.data.result)
      })
    }

    // 有token的情况下，判断分享页面跳转
    if (to.path !== '/login' && window.history.length <= 1) {
      if (
        to.path === '/register' ||
        to.path === '/reset' ||
        to.path === '/register-info'
      )
        return next('/login')
    }

    return next()
  }

  // 无token情况下，判断分享页面跳转
  if (to.path !== '/login' && window.history.length <= 1) {
    return next('/login')
  }

  if (
    to.path === '/register' ||
    to.path === '/login' ||
    to.path === '/reset' ||
    to.path === '/register-info'
  )
    return next()

  return next('/login')
})

import * as filters from './filters' // global filters

// register global utility filters.
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

router.afterEach(() => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
