import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'hash',
  routes: [
    // 登录模块
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/index')
    },
    {
      path: '/reset',
      name: 'reset',
      component: () => import('@/views/login/reset')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/login/register')
    },
    {
      path: '/register-info',
      name: 'register-info',
      component: () => import('@/views/login/register-info')
    },

    //首页(分类页)
    { path: '', name: 'home', component: () => import('@/views/home/index') },

    //推荐页
    {
      path: '/recommend',
      name: 'recommend',
      component: () => import('@/views/recommend/index')
    },

    // 商品详情页
    {
      path: '/goods/:id',
      name: 'goods',
      component: () => import('@/views/goods/index')
    },

    // 购物车
    {
      path: '/carts',
      name: 'carts',
      component: () => import('@/views/carts/index')
    },

    // 创建订单
    {
      path: '/create-order',
      name: 'create-order',
      component: () => import('@/views/create-order/index')
    },

    // 我的页面
    {
      path: '/mine',
      name: 'mine',
      component: () => import('@/views/mine/index')
    },

    // 付款页面
    { path: '/pay', name: 'pay', component: () => import('@/views/pay/index') },

    // 评价页面
    {
      path: '/evaluate/:id',
      name: 'evaluate',
      component: () => import('@/views/evaluate/index')
    },

    {
      path: '/evaluate-detail',
      name: 'evaluate-detail',
      component: () => import('@/views/evaluate/detail')
    },

    // 申请退款
    {
      path: '/refund-apply/:id',
      name: 'refund-apply',
      component: () => import('@/views/refund/apply')
    },

    // 订单详情
    {
      path: '/order-detail/:id',
      name: 'order-detail',
      component: () => import('@/views/mine/order/detail')
    },

    // 搜索页
    {
      path: '/search',
      name: 'search',
      component: () => import('@/views/search/index')
    },

    // 搜索页
    {
      path: '/banner',
      name: 'banner',
      component: () => import('@/views/banner/index')
    },

    // 售后详情
    {
      path: '/afterSale-detail/:id',
      name: 'afterSale-detail',
      component: () => import('@/views/mine/order/afterSale-detail')
    },

    //公告详情
    {
      path: '/notify/:id',
      name: 'notify-detail',
      component: () => import('@/views/recommend/notify/index')
    },
    //工单列表
    {
      path: '/workOrderList',
      name: 'workOrderList',
      component: () => import('@/views/recommend/workOrder/list')
    },
    //工单详情
    {
      path: '/detail',
      name: 'workOrder-detail',
      component: () => import('@/views/recommend/workOrder/detail')
    },
    //关于我们
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: () => import('@/views/about/index.vue')
    },
    //售后商品
    {
      path: '/afterSaleOrder',
      name: 'afterSaleOrder',
      component: () => import('@/views/mine/order/afterSale-order.vue')
    }
  ]
})
