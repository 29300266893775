<template>
  <div>
    <el-dialog
      :visible.sync="addDWorkOrderialog"
      :show-close="false"
      @close="handleClose"
      width="35%"
    >
      <div class="flex flex-column flex-center align-center">
        <div class="header">Solicitud de PQR</div>
        <div v-if="chooseBtn">
          <div
            class="btn mt10 flex flex-center align-center"
            @click="toapply('CONSULTING')"
          >
            Consulta
          </div>
          <div
            class="btn mtb10 flex flex-center align-center"
            @click="toapply('COMPLAINT')"
          >
            Propuesta de denuncia
          </div>
          <div class="btn flex flex-center align-center" @click="toAfterSale">
            Solicitud de reembolso
          </div>
        </div>
        <div class="main" v-else>
          <el-form>
            <el-form-item label="Título de la PQR">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="Descripción detallada">
              <el-input type="textarea" v-model="form.details"></el-input>
            </el-form-item>
            <el-form-item>
              <uploadImg
                folder="head"
                type="images"
                @listenPostImage="postImage($event)"
                :multiple="true"
              />
              <template v-if="uploadList">
                <div class="flex flex-wrap">
                  <div
                    class="imgVisible col-delete"
                    v-for="(imgItem, imgIndex) in uploadList"
                    :key="imgIndex"
                  >
                    <img :src="imgItem | filterPicture" alt="" />
                    <i
                      @click="delImg(uploadList, imgIndex)"
                      class="el-icon-error"
                    />
                  </div>
                </div>
              </template>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDWorkOrderialog = false">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ObjectCopy } from '../myCode/myCode'
import uploadImg from './upload/image.vue'
export default {
  props: ['show'],
  components: {
    uploadImg
  },
  data() {
    return {
      addDWorkOrderialog: false,
      // addDWorkOrderialog:true,
      uploadList: [],
      chooseBtn: true,
      form: {
        details: '',
        imageUrl: '',
        title: '',
        type: ''
      },
      empty: {}
    }
  },
  watch: {
    show(val) {
      this.addDWorkOrderialog = val
      console.log('val', val)
    }
  },
  methods: {
    delImg(imgList, imgIndex) {
      // console.log(imgList, imgIndex)
      imgList.splice(imgIndex, 1)
      this.$forceUpdate()
    },
    postImage(url) {
      console.log(url)
      this.uploadList.push(url)
      this.$forceUpdate()
      console.log(this.uploadList)
    },
    toapply(val) {
      this.chooseBtn = false
      this.form.type = val
    },
    submit() {
      this.form.imageUrl = this.uploadList
      console.log('form', this.form)
      this.$axios.post('/workOrder/add', this.form).then((res) => {
        this.$message.success('Enviado satisfactoriamente')
        this.chooseBtn = true
      })
      ObjectCopy(this.form, this.empty)
      this.$emit('submit')
    },
    handleClose() {
      ObjectCopy(this.form, this.empty)
      this.uploadList = []
      this.chooseBtn = true
      this.$emit('handleClose')
    },
    toAfterSale() {
      this.addDWorkOrderialog = false
      // 设置我的页面缓存，跳转到售后页面
      localStorage.setItem('mineTabIndex', '8')
      this.$router.push({
        path: '/mine',
        name: 'mine',
        params: { tabIndex: 8 }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  cursor: pointer;
  width: 360px;
  height: 60px;
  background: #f9fbf9;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 42px;
}
.header {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 22px;
}
.imgVisible {
  display: inline-block;
  width: 120px;
  margin: 10px 20px 0 0;
  img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    display: block;
  }
}

// 删除图标
.col-delete {
  position: relative;
  .el-icon-error {
    display: none;
    top: -7px;
    right: -7px;
    position: absolute;
    color: #999;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
  &:hover {
    .el-icon-error {
      display: block;
    }
  }
}
</style>
