/*
 * @Author: 52203928+yingzilin@users.noreply.github.com
 * @LastEditTime: 2023-09-25 11:26:56
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import app from './modules/app'
import order from './modules/order'

const store = new Vuex.Store({
  modules: {
    app,
    order
  }
})

export default store
