<template>
  <div>
    <div class="flex flex-between">
      <div class="title">
        {{ notify.title }}
      </div>
      <div class="time mr10">
        {{ notify.createdTime[0] }}
      </div>
    </div>
    <div class="line mt20"></div>
  </div>
</template>
<script>
export default {
  props: {
    notify: {
      type: Object,
      default: () => ''
    }
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.title {
  width: 840px;
  height: aoto;
}
.time {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #979797;
  line-height: 21px;
}
.line {
  width: 1200px;
  height: 1px;
  background: #e7e7e7;
}
</style>
