<template>
  <div class="flex page">
    <div class="left">
      <div>Número de PQR</div>
      <div>{{ list.id }}</div>
    </div>
    <div class="right flex flex-column">
      <div class="top flex flex-between mr10">
        <div>
          {{ list.title || list.orderAftersale.orderGoodsDetail.goodsName }}
        </div>
        <div class="label">{{ orderStatus[list.status] }}</div>
      </div>
      <div class="bottom mt5">
        <div class="label plr10">
          {{ orderType[list.type] }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['list'],
  data() {
    return {
      orderType: {
        CONSULTING: 'consultar',
        COMPLAINT: 'Sugerencias',
        BARTER: 'Regreso'
      },
      orderStatus: {
        UNTREATED: 'Pendiente',
        CANCELLED: 'Cancelado',
        PROCESSING: 'Procesando',
        COMPLETED: 'terminado',
        EVALUATED: 'Evaluado'
      }
    }
  },
  created() {
    //  console.log('list',this.list);
  },
  watch: {
    list(val) {
      // console.log('工单----',val);
    }
  }
}
</script>
<style lang="scss" scoped>
.left {
  width: 350px;
  height: auto;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #282828;
  line-height: 24px;
}
.right {
  width: 100%;
  margin-left: 140px;
  .top {
    .label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d43a57;
      line-height: 21px;
    }
  }
}
.bottom {
  .label {
    display: inline-block !important;
    width: auto;
    height: 18px;
    background: #8acf66;
    border-radius: 9px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
  }
}
.page {
  background: #f9fbf9;
  padding: 16px;
}
</style>
