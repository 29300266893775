export function filterPicture(url) {
  return url && url.indexOf('http') === -1
    ? 'https://calabazapets.s3.amazonaws.com/' + url
    : url
}

export function formatAvatar(row) {
  const defaultAvatar =
    row.sex == 1
      ? 'head/png/2020-02-21/6d61e07b-22d4-4048-9fde-aa751552bc3d.png'
      : 'head/png/2020-02-21/5fd43e81-2bcb-4566-92a3-3f226110ffcd.png'

  return filterPicture(row.head || defaultAvatar)
}

export function formatGender(val) {
  const map = {
    0: '未知',
    1: '男',
    2: '女'
  }

  return map[val] || val
}

export function filterPrice(val) {
  if (!val) {
    return '0'
  }
  val = parseInt(val)

  // 返回千分位
  var reg = /\d{1,3}(?=(\d{3})+$)/g
  return (val + '').replace(reg, '$&.')
}

export function filterTimeStyle(val) {
  return val.substring(0, 10).replace(/-/g, '.') + ' ' + val.substring(11)
}

export function filterPayType(val) {
  const map = {
    WXPAY: '微信支付',
    ALIPAY: '支付宝支付'
  }

  return map[val] || val
}

export function download(link, name) {
  if (!name) {
    name = link.slice(link.lastIndexOf('/') + 1)
  }
  let eleLink = document.createElement('a')
  eleLink.download = name
  eleLink.style.display = 'none'
  eleLink.href = link
  document.body.appendChild(eleLink)
  eleLink.click()
  document.body.removeChild(eleLink)
}

export function filterAfterSaleStatus(val) {
  const map = {
      APPLY: 'Aplicando',
      PASS:'Solicitud aprobada',
      REFUSE:'Solicitud rechazada',
      COMPLETE:'Finalización posventa',
      WAIT_RECEIVED:'Esperando que el comerciante reciba los bienes',
      WAIT_STORE_HANDLE:'En espera de procesamiento en el almacén',
      INSPECTION:'Inspección',
      CONFIRM:'Confirmar',
      DELIVERY:'Barco',
      DELIVERED:'Entrega'
  }

  return map[val] || val
}
