// 深拷贝
export function ObjectCopy(parseForm, originalForm) {
  Object.keys(parseForm).map((key) => (parseForm[key] = originalForm[key]))
}

// 数组去重
export function uniqueArray(arr) {
  if (!Array.isArray(arr)) {
    throw new Error('The first parameter must be an array')
  }
  if (arr.length === 1) {
    return arr
  } else {
    return [...new Set(arr)]
  }
}

// 去除空对象
export const isFalsy = (value) => (value === 0 ? false : !value)
export const isVoid = (value) =>
  value === undefined || value === null || value === ''
export function cleanObject(object) {
  // Object.assign({}, object)
  if (!object) {
    return {}
  }
  const result = { ...object }
  Object.keys(result).forEach((key) => {
    const value = result[key]
    if (isVoid(value)) {
      delete result[key]
    }
  })
  return result
}

/**
 * 获取文件后缀名
 * @param {String} filename
 */
export function getExt(filename) {
  if (typeof filename == 'string') {
    return filename.split('.').pop().toLowerCase()
  } else {
    throw new Error('filename must be a string type')
  }
}
/**
 *深拷贝
 * @export
 * @param {*} obj
 * @returns
 * 缺陷：只拷贝对象、数组以及对象数组，对于大部分场景已经足够
 */
export function deepCopy(obj) {
  if (typeof obj != 'object') {
    return obj
  }
  if (obj == null) {
    return obj
  }
  return JSON.parse(JSON.stringify(obj))
}

/**
 * 对象转化为formdata
 * @param {Object} object
 */

export function getFormData(object) {
  const formData = new FormData()
  Object.keys(object).forEach((key) => {
    const value = object[key]
    if (Array.isArray(value)) {
      value.forEach((subValue, i) => formData.append(key + `[${i}]`, subValue))
    } else {
      formData.append(key, object[key])
    }
  })
  return formData
}

// 保留小数点以后几位，默认2位
export function cutNumber(number, no = 2) {
  if (typeof number != 'number') {
    number = Number(number)
  }
  return Number(number.toFixed(no))
}

export function download(link, name) {
  if (!name) {
    name = link.slice(link.lastIndexOf('/') + 1)
  }
  let eleLink = document.createElement('a')
  eleLink.download = name
  eleLink.style.display = 'none'
  eleLink.href = link
  document.body.appendChild(eleLink)
  eleLink.click()
  document.body.removeChild(eleLink)
}

export function  formatTreeArray(array) {
  if(!Array.isArray(array)){
      return false
  }
  for(var i=0;i<array.length;i++) {
    if(array[i].childrenDict.length < 1) {
      array[i].childrenDict = null
    }else {
      formatTreeArray(array[i].childrenDict)
    }
  }

  return array
}
