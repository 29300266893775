/*
 * @Author: huanglin.like@qq.com
 * @LastEditTime: 2024-01-10 10:09:36
 */
import Vue from 'vue'
const app = {
  state: {
    scrollTop: 0,
    token: '',
    info: {},
    integral: 0,
    contentManagement: '',
    imageWall: {
      url: '',
      visible: false
    }
  },

  mutations: {
    HANDLE_SCROLL: (state, scrollTop) => {
      state.scrollTop = scrollTop
    },

    SET_TOKEN(state, token) {
      state.token = token
      localStorage.token = token
    },

    SET_INFO(state, info) {
      state.info = info
      console.log('userInfo :>> ', state.info)
      localStorage.setItem('info', JSON.stringify(info))
    },

    SHOW_IMAGE_WALL(state, imageUrl) {
      state.imageWall.visible = true
      state.imageWall.url = imageUrl
    },

    HIDDEN_IMAGE_WALL(state) {
      state.imageWall.visible = false
      state.imageWall.url = ''
    },

    SET_INTEGRAL(state, value) {
      state.integral = value
    }
  },

  actions: {
    contentManagement(context, keyWord) {
      return new Promise((resolve) => {
        // @ts-ignore
        Vue.axios
          .post('/contentManagement/auth/list', { condition: keyWord })
          .then((res) => {
            resolve(res.data.result.rows[0].content)
          })
      })
    },

    getUserIntegral({ commit }) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Vue.axios.post('/wallet/myWallet', {}).then((res) => {
          const {
            result: { point }
          } = res.data
          console.log('用户积分----', point)
          commit('SET_INTEGRAL', point)
        })
      })
    }
  }
}

export default app
