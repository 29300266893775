/*
 * @Author: 52203928+yingzilin@users.noreply.github.com
 * @LastEditTime: 2023-09-25 11:51:30
 */

import Vue from 'vue'
const app = {
  state: {
    childrenId: ''
  },

  mutations: {
    SET_CHILDREN_ID: (state, id) => {
      state.childrenId = id
      console.log('当前加单的子订单 :>> ', state.childrenId)
    }
  },

  actions: {}
}

export default app
