<template>
  <div>
    <el-dialog :visible.sync="centerDialogVisible" width="26%" center>
      <div class="title flex flex-center">
        {{ title }}
      </div>
      <div slot="footer" class="dialog-footer flex flex-center">
        <div @click="cancel" class="mr10">Cancelar</div>
        <div style="background: #8acf66; color: #ffffff" @click="comfirm">
          Seguro
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ['title', 'show'],
  data() {
    return {
      centerDialogVisible: false
    }
  },
  watch: {
    show(val) {
      this.centerDialogVisible = val
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    comfirm() {
      this.$emit('comfirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  div {
    cursor: pointer;
    width: 158px;
    height: 48px;
    background: #f9fbf9;
    border-radius: 28px;
    border: 1px solid #e7e7e7;
    line-height: 48px;
  }
}
.title {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 27px;
}
</style>
