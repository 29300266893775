<template>
  <div id="app">
    <vue-page-stack>
      <router-view />
    </vue-page-stack>
    <image-wall />
  </div>
</template>

<script>
// 图片预览
import ImageWall from './components/image-wall'
export default {
  name: 'App',

  components: {
    ImageWall
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop // 滚动条偏移量
      this.$store.commit('HANDLE_SCROLL', scrollTop)
      // console.log(scrollTop)
    }
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>
